import type { FC } from 'react'

import type {
  FindSentimentAnalysisESIScoreQuery,
  FindSentimentAnalysisESIScoreQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { SentimentAnalysisMetricName } from '../SentimentAnalysisHelper'
import SentimentAnalysisCSIScore from '../SentimentAnalysisUserBar/SentimentAnalysisCSIScore'

export const QUERY = gql`
  query FindSentimentAnalysisESIScoreQuery(
    $filterDateRange: SADateFilterOptions
  ) {
    overallESIScore: overallESIScore(filterDateRange: $filterDateRange) {
      overallScore
      landlordStatusCount {
        high
        mid
        low
      }
    }
  }
`

export const Loading = () => (
  <SentimentAnalysisCSIScore
    overallScore={0}
    statusCounts={{ high: 0, mid: 0, low: 0 }}
    tooltip={`${SentimentAnalysisMetricName.esi} over all time`}
    loading={true}
  />
)

export const Empty = () => (
  <SentimentAnalysisCSIScore
    overallScore={0}
    statusCounts={{ high: 0, mid: 0, low: 0 }}
    tooltip={`${SentimentAnalysisMetricName.esi} over all time`}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type Props = CellSuccessProps<
  FindSentimentAnalysisESIScoreQuery,
  FindSentimentAnalysisESIScoreQueryVariables
>
export const Success: FC<Props> = ({ overallESIScore }) => {
  const { overallScore, landlordStatusCount } = overallESIScore
  return (
    <SentimentAnalysisCSIScore
      overallScore={overallScore}
      statusCounts={landlordStatusCount}
      tooltip={`${SentimentAnalysisMetricName.esi} over all time`}
    />
  )
}
