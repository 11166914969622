const SentimentAnalysisDenied = (props: { message: string }) => {
  return (
    <div className="grid min-h-[calc(100vh-200px)] place-items-center justify-center text-center">
      <div>
        <p className="text-lg">{props.message}</p>
        <p className="text-sm text-gray-400">
          Talk to your admin to get access for Email Sentiment.
        </p>
      </div>
    </div>
  )
}

export default SentimentAnalysisDenied
